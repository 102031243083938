import * as React from 'react';

function SvgLocationIcon(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <rect width={48} height={48} rx={2} fill="#EFEDE4" />
      <path
        d="M34.385 19.028c0 8.318-10.302 19.416-10.302 19.416S14 27.346 14 19.028C14 13.694 18.603 9 24.302 9s10.083 4.48 10.083 10.028z"
        stroke="#CE9200"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.194 23.268a4.077 4.077 0 100-8.154 4.077 4.077 0 000 8.154z"
        stroke="#CE9200"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgLocationIcon;
