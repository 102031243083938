import * as React from 'react';

function SvgPhoneIcon(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <rect width={48} height={48} rx={2} fill="#EFEDE4" />
      <path
        d="M16 36.425V12.114c0-1.268.777-2.114 1.941-2.114h12.425c1.165 0 1.942.846 1.942 2.114v24.31c0 1.269-.777 2.114-1.942 2.114H17.941c-1.164 0-1.941-.845-1.941-2.113zM16 30.927h16.308M22.793 34.52h2.912M23.96 13.593h.583"
        stroke="#CE9200"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPhoneIcon;
