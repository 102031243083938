import * as React from 'react';

function SvgMailIcon(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <rect width={48} height={48} rx={2} fill="#EFEDE4" />
      <path
        d="M13.989 15h20.484c1.193 0 1.989.806 1.989 2.016V31.33c0 1.21-.796 2.016-1.99 2.016H13.99c-1.194 0-1.989-.806-1.989-2.016V17.016c0-1.21.796-2.016 1.989-2.016z"
        stroke="#CE9200"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.443 16.02L24.23 27.232 13.02 16.02M28.309 23.154l8.153 8.154M20.154 23.154L12 31.308"
        stroke="#CE9200"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMailIcon;
